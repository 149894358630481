import { Box, Button, Dialog, DialogContent, DialogTitle, List, ListItem, styled, Typography } from '@mui/material';
import palette from '../../ui/palette';
import { useTranslation } from 'react-i18next';
import { ProofButton } from '../../ui/button/ProofButton';
import { useState } from 'react';
import LinkLoader, { IncomeType } from '../../containers/plaid/LinkLoader';
import { submitDocuments } from '../../services/plaid.service';
import { useSelector } from 'react-redux';
import { getPlaidUserInfo } from '../../store/ui/selector';
import { useOptions } from '../../containers/verification';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function UploadIncomeDocuments() {
  const { t } = useTranslation();

  const plaidUser = useSelector(getPlaidUserInfo);
  const { userDetails } = useOptions();
  const { handleNext } = useOptions();

  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  const [step, setStep] = useState(1);

  const onClose = () => {
    setShowUploadDialog(false);
    setFiles([]);
    setStep(1);
  };

  const addFiles = (fls: any, moveStep = true) => {
    const acceptedFiles = [...fls].filter((file: any) => file.type === 'application/pdf');
    setFiles([...files, ...acceptedFiles]);
    moveStep && setStep(step + 1);
  };

  const removeFile = (idx: number) => {
    const fls = files;
    fls.splice(idx, 1);
    setFiles([...fls]);
  };

  const onDone = async () => {
    await submitDocuments(files, plaidUser.applicant_id!!, userDetails?.application_id!!);
    setStep(step + 1);
  };

  return (
    <>
      <Box>
        <ProofButton
          color="primary"
          variant={'text'}
          fullWidth
          sx={{ mb: 2, py: 1 }}
          onClick={() => {
            setShowUploadDialog(true);
          }}
        >
          {t('verification.type.document.button')}
        </ProofButton>
        {/* <LinkLoader variant="text" buttonText={t('verification.type.document.button')} income={true} incomeType={IncomeType.Payroll} isDocument /> */}
      </Box>
      <Dialog open={showUploadDialog} onClose={onClose} maxWidth={'xs'} fullWidth>
        {step === 1 ? (
          <>
            <DialogTitle sx={{ px: 2 }} variant="h5" textAlign={'center'}>
              Upload one of the following documents:
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>(PDFs only)</Box>
            </DialogTitle>
            <DialogContent sx={{ px: 2, pb: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginBottom: 2 }}>
                <Typography>
                  We strongly recommend to link bank account or payroll providers to avoid any delays in parsing documents and faster approvals.
                </Typography>
                <LinkLoader buttonText={'Continue with Bank'} income={true} incomeType={IncomeType.Bank} />
                <LinkLoader buttonText={'Continue with Payroll'} income={true} incomeType={IncomeType.Payroll} />
              </Box>
              <List sx={{ border: '1px solid #ededed', borderRadius: 2, boxShadow: '0 1px 4px 0 rgba(118,118,118,.08)', mb: 2 }}>
                {/* <ListItem sx={{ display: 'flex', gap: 1 }}>
                  <Typography sx={{ fontWeight: 700 }}>1.</Typography>
                  <Typography sx={{ fontWeight: 500 }}>W2 from last year</Typography>
                </ListItem> */}
                {/* <ListItem sx={{ display: 'flex', gap: 1 }}>
                  <Typography sx={{ fontWeight: 700 }}>2.</Typography>
                  <Typography sx={{ fontWeight: 500 }}>1099s from last year</Typography>
                </ListItem> */}
                <ListItem sx={{ display: 'flex', gap: 1 }}>
                  <Typography sx={{ fontWeight: 700 }}>1.</Typography>
                  <Typography sx={{ fontWeight: 500 }}>Last 2 paystubs</Typography>
                </ListItem>
                <ListItem sx={{ display: 'flex', gap: 1 }}>
                  <Typography sx={{ fontWeight: 700 }}>2.</Typography>
                  <Typography sx={{ fontWeight: 500 }}>Offer Letter</Typography>
                </ListItem>
                <ListItem sx={{ display: 'flex', gap: 1 }}>
                  <Typography sx={{ fontWeight: 700 }}>3.</Typography>
                  <Typography sx={{ fontWeight: 500 }}>Last 2 months bank statement</Typography>
                </ListItem>
              </List>
              <Button
                component="label"
                variant="contained"
                fullWidth
                sx={{
                  textTransform: 'none',
                  padding: '0.875rem 1.25rem 0.875rem 1.25rem',
                  fontSize: '1rem',
                  lineHeight: 1.5,
                  fontWeight: 600,
                  borderRadius: '8px',
                  boxShadow: 'none',
                  '&.MuiButton-contained': {
                    backgroundColor: palette.primary[30],
                    '&:hover': {
                      backgroundColor: palette.primary[50],
                    },
                  },
                  '&.MuiButton-outlined': {
                    color: palette.primary[30],
                    borderColor: 'rgba(39, 41, 55, 0.35)',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: palette.grey[30],
                  },
                  '&.MuiButton-outlined:hover': {
                    backgroundColor: palette.white,
                  },
                  '&.MuiButton-textPrimary': {
                    color: palette.primary[30],
                  },
                }}
              >
                Continue
                <VisuallyHiddenInput type="file" accept="application/pdf" onChange={event => addFiles(event.target.files)} multiple />
              </Button>
            </DialogContent>
          </>
        ) : step === 2 ? (
          <>
            <DialogTitle sx={{ px: 2 }} variant="h5" textAlign={'center'}>
              Documents Added
            </DialogTitle>
            <DialogContent sx={{ px: 2, pb: 2 }}>
              <Box sx={{ mb: 2 }}>
                {files.map((file, idx) => (
                  <Box sx={{ padding: 1, display: 'flex', justifyContent: 'space-between' }} key={`item-${idx}`}>
                    <Typography>{file.name}</Typography>
                    <Typography sx={{ cursor: 'pointer' }} onClick={() => removeFile(idx)}>
                      x
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Button
                component="label"
                variant="contained"
                fullWidth
                sx={{
                  mb: 2,
                  textTransform: 'none',
                  padding: '0.875rem 1.25rem 0.875rem 1.25rem',
                  fontSize: '1rem',
                  lineHeight: 1.5,
                  fontWeight: 600,
                  borderRadius: '8px',
                  boxShadow: 'none',
                  '&.MuiButton-contained': {
                    backgroundColor: palette.primary[30],
                    '&:hover': {
                      backgroundColor: palette.primary[50],
                    },
                  },
                  '&.MuiButton-outlined': {
                    color: palette.primary[30],
                    borderColor: 'rgba(39, 41, 55, 0.35)',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: palette.grey[30],
                  },
                  '&.MuiButton-outlined:hover': {
                    backgroundColor: palette.white,
                  },
                  '&.MuiButton-textPrimary': {
                    color: palette.primary[30],
                  },
                }}
              >
                Add more Documents
                <VisuallyHiddenInput type="file" accept="application/pdf" onChange={event => addFiles(event.target.files, false)} multiple />
              </Button>
              <ProofButton variant="outlined" fullWidth onClick={() => onDone()}>
                Done
              </ProofButton>
            </DialogContent>
          </>
        ) : step === 3 ? (
          <>
            <DialogTitle sx={{ px: 2 }} variant="h5" textAlign={'center'}>
              Upload Complete
            </DialogTitle>
            <DialogContent sx={{ px: 2, pb: 2 }}>
              <Typography sx={{ mt: 4, mb: 4, padding: 4, textAlign: 'center' }}>Your documents are successfully submitted for review.</Typography>
              <ProofButton
                variant="outlined"
                fullWidth
                onClick={() => {
                  onClose();
                  handleNext();
                }}
              >
                Close
              </ProofButton>
            </DialogContent>
          </>
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
}
