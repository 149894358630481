import { useState } from 'react';
import LaunchLink from '../../components/plaid/LinkLauncher';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLinkToken, setIncomeSuccessful } from '../../services/plaid.service';
import { setVerificationStep, updatePlaidUserInfo } from '../../store/ui/actions';
import { getPlaidUserInfo } from '../../store/ui/selector';
import { ProofButton } from '../../ui/button/ProofButton';
import { useTranslation } from 'react-i18next';
import { IncomeRequest } from '../../services/plaid.types';
import { useLocation, useNavigate, useParams } from 'react-router';

export enum IncomeType {
  Bank = 'bank',
  Payroll = 'payroll',
}

interface Props {
  income: boolean;
  incomeType: IncomeType;
  buttonText: string;
  variant?: 'contained' | 'text' | 'outlined';
  isDocument?: boolean;
}

/**
 * Grabs a link token from the server, calls Link, and then either sends the
 * public token back down to the server, or just reports success back ot the
 * sever. The behavior of this changes quite a bit depending on whether
 * or not you're using Plaid Income.
 */

const LinkLoader = ({ income, incomeType, buttonText, variant = 'contained', isDocument }: Props) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchObj = new URLSearchParams(search);
  const token = searchObj.get('token');
  const dispatch = useDispatch();

  const plaidUserInfo = useSelector(getPlaidUserInfo);
  let { propertyCode } = useParams();
  const [linkToken, setLinkToken] = useState('');
  const email = plaidUserInfo?.email;
  const { i18n } = useTranslation();

  const loadAndLaunchLink = async () => {
    const linkdPayload: IncomeRequest = {
      income,
      incomeType,
      client_user_id: `plaid_${process.env.REACT_APP_APP_ENV !== 'prod' ? 'dev' : 'prod'}_${plaidUserInfo.applicant_id || propertyCode}`,
      email,
      applicantId: plaidUserInfo.applicant_id,
      lang: i18n.language,
    };
    if (incomeType === IncomeType.Payroll) {
      linkdPayload.flow = isDocument ? 'doc' : 'link';
    }
    const tokenResponse = await fetchLinkToken(linkdPayload);
    if (tokenResponse?.status === 200) {
      setLinkToken(tokenResponse.data?.link_token || '');
    }
  };

  const linkSuccess = async (public_token: string) => {
    if (public_token !== null && public_token !== '') {
      if (income) {
        await incomeSuccess(public_token);
      } else {
        await accessTokenSuccess(public_token);
      }
    }
  };

  const incomeSuccess = async (public_token: string) => {
    dispatch(setVerificationStep(6));
    const response = await setIncomeSuccessful({ email, incomeType, public_token, applicantId: plaidUserInfo.applicant_id });
    if (response.status) {
      dispatch(
        updatePlaidUserInfo({
          income_status: true,
        })
      );
      navigate(`/confirmation?token=${token}`);
    }
  };

  const accessTokenSuccess = async (public_token: string) => {
    await fetch('/appServer/swap_public_token', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        public_token: public_token,
      }),
    });
  };

  return (
    <>
      <ProofButton color="primary" variant={variant} fullWidth sx={{ mb: 2, py: 1 }} onClick={() => loadAndLaunchLink()}>
        {buttonText}
      </ProofButton>
      {linkToken && <LaunchLink token={linkToken} successCallback={linkSuccess} />}
    </>
  );
};

export default LinkLoader;
