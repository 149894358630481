import { Box, Container, InputLabel, Stack, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TSInput } from '../../../ui/input/input';
import { ProofButton } from '../../../ui/button/ProofButton';
import { useDispatch } from 'react-redux';
import { updatePlaidUserInfo } from '../../../store/ui/actions';
import { startPlaidVerification } from '../../../services/plaid.service';
import { useEffect, useState } from 'react';
import palette from '../../../ui/palette';
import { ApplicantionFromToken } from '../../../models/doc-upload.model';
import { useOptions } from '..';

export interface IncomeVerificationStepProps {
  userDetails?: ApplicantionFromToken;
  handleNext: any;
  handleBack?: VoidFunction;
}

export default function IncomeVerificationAccount() {
  const { userDetails, handleNext } = useOptions();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const isGuarantor = userDetails?.role === 'Guarantor' || userDetails?.role === 'Non-Resident Co-sign';
  const { control, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      propertyName: userDetails?.propertyName || '',
      unitNumber: userDetails?.unitNumber || '',
      fullName: userDetails?.fullName || '',
      email: userDetails?.email || '',
      phoneNumber: userDetails?.phoneNumber || '',
      application_id: userDetails?.application_id || '',
      applicant_id: userDetails?.applicant_id || '',
    },
  });

  useEffect(() => {
    if (userDetails?.propertyName) {
      setValue('propertyName', userDetails?.propertyName);
    }
    if (userDetails?.unitNumber) {
      setValue('unitNumber', userDetails?.unitNumber);
    }
    if (userDetails?.fullName) {
      setValue('fullName', userDetails?.fullName);
    }
    if (userDetails?.email) {
      setValue('email', userDetails?.email);
    }
    if (userDetails?.phoneNumber) {
      setValue('phoneNumber', userDetails?.phoneNumber);
    }
    if (userDetails?.application_id) {
      setValue('application_id', userDetails?.application_id);
    }
    if (userDetails?.applicant_id) {
      setValue('applicant_id', userDetails?.applicant_id);
    }
  }, [userDetails]);

  const onSubmit = async (data: any) => {
    setErrorMessage('');

    const verificationResponse = await startPlaidVerification(data);
    if (typeof verificationResponse === 'string') {
      setErrorMessage(verificationResponse);
    } else {
      dispatch(updatePlaidUserInfo({ ...data, ...verificationResponse, plaidUserId: verificationResponse._id }));
      handleNext();
    }
  };

  return (
    <Container maxWidth="xs" sx={{ px: 3 }}>
      <Typography variant="h2" sx={{ fontWeight: 500, mb: { xs: 2, md: 2.5 } }}>
        {t('verification.account.title')}
      </Typography>
      <Typography variant="body1" sx={{ mb: { xs: 4, md: 5 } }}>
        {t('verification.account.desc')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={3} sx={{ mb: 4 }}>
          {!isGuarantor && (
            <Controller
              name="propertyName"
              control={control}
              rules={{ required: t('page1.inputs.property.error.required') }}
              render={({ field, fieldState: { error } }) => (
                <Box>
                  <InputLabel sx={{ mb: 1 }}>{t('page1.inputs.property.label')}</InputLabel>
                  <TSInput {...field} error={!!error} helperText={error?.message || null} placeholder={t('page1.inputs.property.placeholder')} />
                </Box>
              )}
            />
          )}
          {!isGuarantor && (
            <Controller
              name="unitNumber"
              control={control}
              rules={{ required: t('page1.inputs.unitNumber.error.required') }}
              render={({ field, fieldState: { error } }) => (
                <Box>
                  <InputLabel sx={{ mb: 1 }}>{t('page1.inputs.unitNumber.label')}</InputLabel>
                  <TSInput {...field} error={!!error} helperText={error?.message || null} placeholder={t('page1.inputs.unitNumber.placeholder')} />
                </Box>
              )}
            />
          )}
          <Controller
            name="fullName"
            control={control}
            rules={{ required: t('page1.inputs.fullName.error.required') }}
            render={({ field, fieldState: { error } }) => (
              <Box>
                <InputLabel sx={{ mb: 1 }}>{t('page1.inputs.fullName.label')}</InputLabel>
                <TSInput {...field} error={!!error} helperText={error?.message || null} placeholder={t('page1.inputs.fullName.placeholder')} />
              </Box>
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Box>
                <InputLabel sx={{ mb: 1 }}>{t('page1.inputs.email.label')}</InputLabel>
                <TSInput {...field} placeholder={t('page1.inputs.email.placeholder')} disabled />
              </Box>
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Box>
                <InputLabel sx={{ mb: 1 }}>{t('page1.inputs.phoneNumber.label')}</InputLabel>
                <TSInput {...field} placeholder={t('page1.inputs.phoneNumber.placeholder')} disabled />
              </Box>
            )}
          />
          {errorMessage && <Box sx={{ backgroundColor: palette.error[60], borderRadius: 2, p: 2, color: 'white' }}>{errorMessage}</Box>}
        </Stack>
        <ProofButton disabled={!userDetails?.email} type="submit" variant="contained" fullWidth>
          {t('page.continue')}
        </ProofButton>
      </form>
    </Container>
  );
}
