import { Box, Button, Container, Divider, Stack, Typography } from '@mui/material';
import VerificationType, { VerifyIncomeType } from '../../../components/verification/VerificationType';
import Slider from 'react-slick';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTranslation } from 'react-i18next';
import UploadIncomeDocuments from '../../../components/verification/UploadIncomeDocuments';
import { useOptions } from '..';

export default function IncomeVerificationTypes() {
  const { t } = useTranslation();
  const { handleBack } = useOptions();
  const slideSettings = {
    dots: true,
    infinite: false,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Container maxWidth="lg" sx={{ px: { xs: 0, md: 3 } }}>
      <Container maxWidth="xs" sx={{ mb: 5, px: { xs: 3, md: 0 } }}>
        <Box sx={{ display: { xs: 'block', md: 'none' }, pt: 1.5, pb: 2 }}>
          <Button sx={{ textTransform: 'none', px: 0 }} onClick={handleBack}>
            <ChevronLeftIcon />
            {t('verification.type.back')}
          </Button>
        </Box>
        <Typography variant="h2" mb={2.5} textAlign="center" sx={{ fontWeight: 500, textAlign: { xs: 'left', md: 'center' } }}>
          {t('verification.type.title')}
        </Typography>
        <Typography variant="body1" sx={{ textAlign: { xs: 'left', md: 'center' } }}>
          {t('verification.type.desc')}
        </Typography>
      </Container>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          pb: 5,
          width: '100vw',
          '& .slick-list': {
            px: '24px !important',
          },
          '& .slick-track': {
            display: 'flex',
          },
          '& .slick-slide': {
            height: 'auto',
            '& > div': {
              px: 1,
              height: '100%',
            },
          },
          '& .slick-dots': {
            bottom: '-45px',
          },
        }}
      >
        <Slider {...slideSettings}>
          {verifyTypes.map(verifyType => (
            <VerificationType key={verifyType.type} type={verifyType.type} />
          ))}
        </Slider>
      </Box>
      <Container
        maxWidth="sm"
        sx={{
          display: { xs: 'none', md: 'grid' },
          gridTemplateColumns: '1fr 1fr',
          gap: 3,
          px: [0, 0, 1],
          pb: 5,
        }}
      >
        {verifyTypes.map(verifyType => (
          <VerificationType key={verifyType.type} type={verifyType.type} />
        ))}
      </Container>
      <Container maxWidth="sm" sx={{ px: [4, 4, 1] }}>
        <Stack flexDirection="row" alignItems="center">
          <Divider orientation="horizontal" sx={{ flex: 1, borderColor: '#979797' }} />
          <Typography sx={{ color: '#979797', width: '40px', textAlign: 'center', my: 1.5, fontWeight: 600 }}>OR</Typography>
          <Divider orientation="horizontal" sx={{ flex: 1, borderColor: '#979797' }} />
        </Stack>
        <UploadIncomeDocuments />
      </Container>
    </Container>
  );
}

const verifyTypes: { type: VerifyIncomeType }[] = [
  {
    type: VerifyIncomeType.Bank_Account,
  },
  {
    type: VerifyIncomeType.Payroll_Provider,
  },
  // {
  //   type: VerifyIncomeType.Paystubs,
  // },
];
